import {Component, OnDestroy, OnInit} from '@angular/core';
import {AchievementService} from '../../services/achievement.service';
import {UserAchievement} from '../../interfaces/achievement';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';
import {ActionSheetController} from '@ionic/angular';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-my-achievements',
  templateUrl: './my-achievements.component.html',
  styleUrls: ['./my-achievements.component.scss'],
})
export class MyAchievementsComponent implements OnInit, OnDestroy {
  myAchievements: UserAchievement[] = [];
  myAchievementsSubscription = new Subscription();

  constructor(private _achievements: AchievementService,
              private actionSheetCtrl: ActionSheetController,
              private socialSharing: SocialSharing,
              private _user: UserService) {
  }

  ngOnInit() {
    this.myAchievementsSubscription = this._achievements.getMyAchievements(UserService.uid).subscribe(async achievements => {
      this.myAchievements = await this._achievements.populate(achievements.map(achievement => ({
        ...achievement,
        ref: this._achievements.getReference(`achievements/${achievement.key}`)
      })), ['ref']) as any;
    });
  }

  ngOnDestroy() {
    this.myAchievementsSubscription.unsubscribe();
  }

  async handleAchievementClick(achievement: UserAchievement, completedGoal) {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Compartir',
          handler: () => {
            this.socialSharing.share(`${this._user.user.name} ha desbloqueado un logro por ver ${completedGoal} videos de ${achievement.ref.name}`, 'Logro en Umana App', null, `https://umana.com.mx`);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel'
        },
      ],
    });

    await actionSheet.present();
  }
}
