import {EventEmitter, Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {FirebaseDataService} from './firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase';
import User = firebase.User;
import UserCredential = firebase.auth.UserCredential;
import {UserService} from '../services/user.service';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  user: User;
  $user: BehaviorSubject<User> = new BehaviorSubject<User>(this.user);
  userEmitter: EventEmitter<User> = new EventEmitter;
  $isUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private afAuth: AngularFireAuth,
              private db: FirebaseDataService,
              private afs: AngularFirestore,
              private router: Router) {
    this.afAuth.onAuthStateChanged(user => {
      if (!!user) {
        this.$isUser.next(user.email != 'guest@umana.com');
      }
    });
  }

  static getMessageError(code: string): string {
    switch (code) {
      case 'auth/email-already-exists':
        return 'Correo electrónico en uso.';

      case 'auth/invalid-email' :
        return 'Correo electrónico inválido.';

      case 'auth/operation-not-allowed':
        return 'Esta operación no está permitida.';

      case 'auth/weak-password':
        return 'Contraseña muy débil.';

      case 'auth/wrong-password':
        return 'Contraseña incorrecta';

      case 'auth/invalid-password':
        return 'Contraseña inválida, mínimo 6 caracteres';

      case 'auth/invalid-uid':
        return 'Usuario inválido';

      case 'auth/user-disabled':
        return 'Tu usuario ha sido bloqueado';

      case 'auth/user-not-found':
        return 'El usuario no fue encontrado, revise su correo electrónico';

      case 'auth/network-request-failed':
        return 'Error en la conexión';

      case 'auth/email-already-in-use':
        return 'El email ya está en uso';

      default:
        console.log(code);
        return `Pruebe de nuevo. Si el problema persiste contacte con el equipo de soporte: soporte@tecnologiasintech.com`;
    }
  }

  async getCurrentUser(): Promise<firebase.User> {
    if (!this.user) {
      return await this.loadFirebaseUser();
    }
    return this.user;
  }

  async loadFirebaseUser(): Promise<User> {
    return new Promise(resolve => {
      this.afAuth.user.subscribe(data => {
        this.user = data;
        this.userEmitter.emit(data);
        this.$user.next(data);
        resolve(data);
      });
    });
  }

  // isUserAnonymous(): Promise<boolean> {
  //   return new Promise(resolve => {
  //     this.afAuth.onAuthStateChanged(user => {
  //       if (user.isAnonymous) {
  //         this.isUser = false;
  //         console.log(user);
  //         // let uid = user.uid;
  //         resolve(true);
  //         // console.log(uid);
  //       } else {
  //         this.isUser = true;
  //         resolve(false);
  //       }
  //     });
  //   });
  // }

  loginWithPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  createAccount(email: string, pass: string): Promise<UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, pass);
  }

  async signOut(): Promise<void> {
    await this.afAuth.signOut();
    await this.router.navigateByUrl('auth/login');
  }

  loginWest() {
    return this.afAuth.signInAnonymously();
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise(resolve => {
      this.afAuth.user.pipe(take(1)).subscribe(async user => {
        if (!!user) {
          UserService.uid = user.uid;
          UserService.uidEmitter.next(UserService.uid);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  restorePassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}

