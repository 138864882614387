import { Routes } from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {WelcomeComponent} from "./pages/welcome/welcome.component";
import {ChooseAuthComponent} from "./pages/choose-auth/choose-auth.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";

export const AuthRoutes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: []
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: []
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: []
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: []
      },
      {
        path: 'choose',
        component: ChooseAuthComponent,
        canActivate: []
      },
      {
        path: '**',
        redirectTo: 'welcome'
      }
    ]
  }
];
