import {Component, OnInit, ViewChild} from '@angular/core';
import {SearchComponent} from '../../modals/search/search.component';
import {VideoService} from '../../../shared/services/video.service';
import {IonSlides, ModalController, Platform} from '@ionic/angular';
import {AuthService} from '../../../shared/template-services/auth.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {CategoryService} from '../../../shared/services/category.service';
import {MembershipService} from '../../../shared/services/membership.service';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {UserService} from '../../../shared/services/user.service';
import {Challenge} from '../../../shared/interfaces/challenge';
import {ChallengeService} from '../../../shared/services/challenge.service';
import {take} from 'rxjs/operators';
import {Category} from '../../../shared/interfaces/category';
import {Video} from '../../../shared/interfaces/video';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import * as _ from 'lodash';
import {SettingService} from '../../../shared/services/setting.service';

declare const $: any;

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({height: '43px', overflow: 'hidden'})),
      state('open', style({height: '*'})),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ChallengesComponent implements OnInit {
  sliderFree = {
    zoom: false,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: -20
  };

  sliderFreeWeb = {
    zoom: false,
    slidesPerView: 5,
    centeredSlides: false,
    spaceBetween: -20
  };

  sliderLastVideos = {
    zoom: false,
    slidesPerView: 1.5,
    centeredSlides: false,
    spaceBetween: -20
  };

  sliderLastVideosWeb = {
    zoom: false,
    slidesPerView: 3.5,
    centeredSlides: false,
    spaceBetween: -20
  };
  sliderNewVideos = {
    initialSlide: 0,
    autoplay: true,
  };
  sliderPresentNewVideos: any = {
    isBeginningSlide: true,
    isEndSlide: false
  };
  @ViewChild('slideWithNav', {static: false})
  slideWithNav: IonSlides;
  loaderVideos: boolean = true;
  videos: Video[] = [];
  videosSubscription: Subscription = new Subscription();
  videosCarouselSubscription: Subscription = new Subscription();
  videosCategoriesSubscription: Subscription = new Subscription();
  isUser: boolean = false;
  // categories$: Observable<any>;
  userSubscribe: Subscription = new Subscription();
  freeVideos: Video[] = [];
  isWeb: boolean = false;
  videosCarousel: Video[] = [];
  videoCarousel: Video;
  categories: Category[];
  categoriesOrigin: Category[];
  videosCategories: Video[];
  memberships;
  tabSelected = 'calendar';
  favorites = [];
  history = [];
  folded = 'closed';
  monthChallengeSubscription: Subscription = new Subscription();
  monthChallenge: any;
  monthSelected: Challenge;
  challengesFiltered: any;
  challengeSubcription: Subscription = new Subscription();
  wellnessSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };

  constructor(private _video: VideoService,
              private platform: Platform,
              private modal: ModalController,
              private _auth: AuthService,
              private _alert: AlertService,
              public router: Router,
              private iab: InAppBrowser,
              private _category: CategoryService,
              private _membership: MembershipService,
              private _history: VideoHistoryService,
              private _favorite: FavoriteService,
              public modalController: ModalController,
              private _settings: SettingService,
              private _user: UserService,
              private plt: Platform,
              private _challenge: ChallengeService) {
    this.loadSettings();
  }

  async ngOnInit() {
    await this._auth.getCurrentUser();

    this.history = await this._history.getAll().pipe(take(1)).toPromise();
    this.favorites = await this._favorite.getAllByUser(this._auth.user.uid).pipe(take(1)).toPromise();

    this.plt.ready().then(() => {
      if (this.plt.is('desktop')) {
        return this.isWeb = true;
      }
    });

    this.monthChallengeSubscription = this._challenge.getMonthChallenge().subscribe(data => {
      this.monthChallenge = data;
    });

    this.categories = await this._category.getAll().pipe(take(1)).toPromise();

    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
    });

    this.videosSubscription = this._video.getLastFive()
      .subscribe(async data => {
        this.loaderVideos = false;

        this.freeVideos = data.filter(video => !!video.memberships.find(membership => membership.id == 'y1pI4abvw2QWZGyM7v0F'));
        this.videos = await data.filter(video => !video.memberships.find(membership => membership.id == 'y1pI4abvw2QWZGyM7v0F'))
          .map(video => ({
            ...video,
            isNew: moment(video.createdAt).days() <= 5,
            isFavorite: this.favorites.some(favorite => favorite.reference.id == video.key),
            isHistory: this.history.some(history => history.reference.id == video.key)
          }));
      });

    let data = (await this._video.getAllWell())
      .map(video => ({
        ...video,
        isNew: moment(new Date()).diff(video.createdAt, 'day') <= 5,
        isFavorite: this.favorites.some(favorite => favorite.reference.id == video.key),
        isHistory: this.history.some(history => history.reference.id == video.key)
      }));

    this.videosCategories = data;
    this.categories = this.categories.map(category => ({
      ...category,
      videos: this.videosCategories.filter(video => video.categories.find(videoCategory => videoCategory.id == category.key))
    }));

    this.categoriesOrigin = [...this.categories];
    this.categories = _.orderBy(this.categoriesOrigin.filter(video => video.program == 'challenge'), 'date', 'asc');
    this.challengesFiltered = this.categories;

    this.videosCarouselSubscription = this._video.getAllCarousel().subscribe(data => {
      this.videosCarousel = data;
      setTimeout(() => {
        let i = 0;
        setInterval(() => {
          this.videoCarousel = this.videosCarousel[i];
          i = (i + 1) % this.videosCarousel.length;
        }, 5000);
      }, 5000);
    });
  }

  expand() {
    $(document).ready(function() {
      //your code here
    });
  }

  loadSettings() {
    this._settings.getAll().pipe(take(1)).subscribe(data => {
      const indexedSettings = _.keyBy(data, 'key');
      this.wellnessSection = indexedSettings['challenge'];
    });
  }

  ngOnDestroy() {
    this.videosCarouselSubscription.unsubscribe();
    this.videosSubscription.unsubscribe();
    this.userSubscribe.unsubscribe();
    this.videosCategoriesSubscription.unsubscribe();
    this.monthChallengeSubscription.unsubscribe();
    this.challengeSubcription.unsubscribe();
  }

  getPercentTimeVideo(currentTime: number, duration: number): number {
    return currentTime / duration;
  }

  async openSearch() {
    const modal: any = await this.modal.create({
      component: SearchComponent
    });

    modal.present();
  }

  async goToVideoDetail(video: Video) {
    if (!await this._auth.isAuthenticated()) {
      this.isUser = true;
      this.signOutGuest();
    }
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  goToVideos(category: any) {
    this._video.query = {
      categories: [category],
      duration: 99999,
      title: '',
      tools: [],
      difficulty: null
    };
    this.router.navigateByUrl('user/workout-serie-details/challenges/null');
  }

  slideDidChange(slider, slideView) {
    this.checkIfNavDisable(slider, slideView);
  }

  checkIfNavDisable(slider, slideView) {
    this.checkIsNewVideos(slider, slideView);
    this.chekIsEnd(slider, slideView);
  }

  checkIsNewVideos(slider, slideView) {
    slideView.isBeginning().then((isTrue) => {
      slider.isBeginningSlide = isTrue;
    });
  }

  chekIsEnd(slider, slideView) {
    slideView.isEnd().then((isTrue) => {
      slider.isEndSlide = isTrue;
    });
  }

  handleChangeTab($event: any) {
    const tab = $event.detail.value;
    this.tabSelected = tab;
    this.categories = this.categoriesOrigin.filter(category => category.program == tab);
  }

  toggleFold() {
    this.folded = this.folded === 'open' ? 'closed' : 'open';
  }

  filterByMonth() {
    this.challengesFiltered = this.categories.reduce((acc, ele, i) => {
      if (ele.key.includes(this.monthSelected.key)) {
        acc.push(ele);
      }
      return acc;
    }, []);
  }
}
