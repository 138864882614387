import {Component, OnInit} from '@angular/core';

import {MenuController, ModalController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './shared/template-services/auth.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Router} from '@angular/router';
import {AlertService} from './shared/template-services/alert.service';
import {UserService} from './shared/services/user.service';
import {User} from './shared/interfaces/user';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {InAppPurchase2} from '@ionic-native/in-app-purchase-2/ngx';
import {Membership} from './shared/interfaces/membership';
import {MembershipService} from './shared/services/membership.service';
import {environment} from '../environments/environment';
import {SettingService} from './shared/services/setting.service';
import {CancellationOpinionComponent} from './user/modals/cancellation-opinion/cancellation-opinion.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/user/tabs/home',
      icon: 'home'
    },
    {
      title: 'Workout series',
      url:'/user/workout-series',
      icon: 'flame'
    },
    {
      title: 'Calendario Semanal',
      url: '/user/calendar',
      icon: 'calendar'
    },
    {
      title: 'Challenge',
      url:'/user/challenges',
      icon: 'flag'
    },
    {
      title: 'Mis logros',
      url:'/user/my-achievements',
      icon: 'trophy'
    },
    {
      title: 'Wellness',
      url:'/user/recipes',
      icon: 'scale'
    },
    {
      title: 'Programas',
      url:'/user/all-programs',
      icon: 'scale'
    },
    {
      title: 'Todos los videos',
      url: '/user/all-videos',
      icon: 'videocam'
    },
    {
      title: 'Membresías',
      url: '/user/memberships',
      icon: 'videocam'
    },
    {
      title: 'Favoritos',
      url: '/user/tabs/favorites',
      icon: 'heart'
    },
    {
      title: 'Historial de pagos',
      url: '/user/payment-history',
      icon: 'card'
    }
  ];
  user: any = null;
  isUser: boolean = false;
  person: User = null;
  memberships: Membership[];
  memberships$: Observable<Membership[]>;
  version = environment.appVersion;
  setting: any
  email: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public _auth: AuthService,
    private iab: InAppBrowser,
    private router: Router,
    private _alert: AlertService,
    private _user: UserService,
    private store: InAppPurchase2,
    private _membership: MembershipService,
    public menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private _setting: SettingService){
    this.initializeApp();
    this._auth.isAuthenticated();
    UserService.uidEmitter.subscribe(async userID => {
      if (userID != null) this.person = await this._user.get(userID).pipe(take(1)).toPromise();
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#31334c');
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    this.setting = await this._setting.getAll().pipe(take(1)).toPromise();
    this.email = 'mailto:' + this.setting[3].email
    this._auth.$isUser.subscribe(isUser => {
      this.isUser = isUser;
    });

    this.memberships$ = this._membership.getAll();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();

    if (this.platform.is('ios')) {
      this.registerProducts()
    }

    this._auth.userEmitter.subscribe(user => this.user = user);
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  registerProducts() {
    for (let membership of this.memberships) {
      this.store.register({
        id: membership.productId,
        type: this.store.NON_RENEWING_SUBSCRIPTION
      });
    }
    this.store.refresh();
  }

  async signOut() {
    localStorage.setItem('isLogin', 'false');
    this.isUser = true;
    await this._auth.signOut();
  }

  async goToLogin() {
    await this._auth.signOut();
  }

  async openMembership() {
    if (this.platform.is('desktop')) {
      return this.iab.create('https://pagos.umana.com.mx', '_blank');
    }
    this.router.navigateByUrl('/user/tabs/memberships')
  }

  openIntech() {
    this.iab.create('https://tecnologiasintech.com', '_blank');
  }

  hasActiveMembership() {
    if (!this.user) return false;

    return !!this.user
      && !!this.user.membership
      && this.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F'
      && (this.user.membership.endTime == 95646466800000
        || this.user.membership.endTime == 32503705200000
        || this.user.membership.endTime == 1831060208000);
  }

  async cancelSubscription() {
    if (await this._alert.confirm('¿Estás seguro que deseas cancelar esta membresía?', 'Podrás seguir disfrutando de los beneficios hasta la fecha de vencimiento', 'Aceptar', 'Cerrar')) {

      const modal = await this.modalCtrl.create({
        component: CancellationOpinionComponent,
        swipeToClose: false,
        backdropDismiss: false,
        keyboardClose: false,
        componentProps: {
          user: {...this.user},
        }
      });

      await modal.present();
      await modal.onWillDismiss();

      const resp: { ok: boolean, endTime: number } = await this._membership.cancel(this._user.user.subscription, this._user.user.email);

      await this._user.update(this._user.user.key, {
        membership: {
          ...this._user.user.membership,
          endTime: resp.endTime,
        }
      } as User);

      this._alert.presentToast('Se ha cancelado los pagos recurrentes a esta membresía');
    }
  }
}
