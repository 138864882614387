import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {WelcomeSlideService} from '../../services/welcome-slide.service';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  @ViewChild('welcome', {static: true}) slides: any;
  isWelcome: boolean;
  welcomeSlides = [];

  constructor(private router: Router,
              private _welcomeSlide: WelcomeSlideService) {
  }

  async ngOnInit() {
    this.welcomeSlides = _.orderBy(await this._welcomeSlide.getAll().pipe(take(1)).toPromise(), 'position', 'asc');
  }

  swipeNext() {
    this.slides.slideNext();
    if (this.isWelcome) this.router.navigateByUrl('auth/choose');
    this.isWelcome = this.slides.isEnd();
  }
}
