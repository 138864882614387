import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {IonSlides, ModalController, Platform} from '@ionic/angular';
import {AuthService} from '../../../shared/template-services/auth.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Router} from '@angular/router';
import {UserService} from '../../../shared/services/user.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {RecipeService} from '../../services/recipe.service';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {IRecipe} from '../../interfaces/IRecipe';
import {SettingService} from '../../../shared/services/setting.service';
import {MembershipService} from '../../../shared/services/membership.service';

declare const $: any;

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({height: '43px', overflow: 'hidden'})),
      state('open', style({height: '*'})),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class RecipesComponent implements OnInit, OnDestroy {
  sliderFree = {
    zoom: false,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: -20
  };
  sliderFreeWeb = {
    zoom: false,
    slidesPerView: 5,
    centeredSlides: false,
    spaceBetween: -20
  };

  @ViewChild('slideWithNav', {static: false})
  slideWithNav: IonSlides;
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  isWeb: boolean = false;
  folded = 'closed';
  recipes: { type: number, data: IRecipe[] }[] = [];
  wellnessSection = {
    title: '',
    titleColor: '',
    imageUrl: '',
    bubbleColor: ''
  };
  memberships;

  constructor(private platform: Platform,
              private _auth: AuthService,
              private _alert: AlertService,
              public router: Router,
              private iab: InAppBrowser,
              public modalController: ModalController,
              private _user: UserService,
              private _recipes: RecipeService,
              private _settings: SettingService,
              private _membership: MembershipService,
              private plt: Platform) {
    this.loadSettings();
  }

  async ngOnInit() {
    await this._auth.getCurrentUser();
    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.plt.ready().then(() => {
      if (this.plt.is('desktop')) {
        return this.isWeb = true;
      }
    });

    this.userSubscribe = this._user.getCurrentUser().subscribe(data => {
      this._user.user = data;
    });

    const allRecipes = await this._recipes.getAll().pipe(take(1)).toPromise();

    _.mapKeys(_.groupBy(allRecipes, 'type'), (value, key) => {
      this.recipes.push({
        type: key,
        data: value
      });
    });
  }

  loadSettings() {
    this._settings.getAll().pipe(take(1)).subscribe(data => {
      const indexedSettings = _.keyBy(data, 'key');
      this.wellnessSection = indexedSettings['wellness'];
    });
  }


  hasFreeTrialMembership(membershipKey: string): boolean {
    if (membershipKey == 'y1pI4abvw2QWZGyM7v0F') return true;

    const membership = this.memberships.find(membership => membership.key == membershipKey);

    return !!membership && membership.isFree;
  }


  async goToUrl(url) {
    if (this.hasFreeTrialMembership(this._user.user.membership.reference.id)) {
      return this._alert.presentAlert('No cuentas con la membresía para ver este video', '');
    }

    if (!this.platform.is('cordova')) {
      window.open(url, '_blank');
    } else {
      this.iab.create(url, '_blank');
    }
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
  }

  toggleFold() {
    this.folded = this.folded === 'open' ? 'closed' : 'open';
  }
}
