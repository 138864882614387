import {Component, Input, OnInit} from '@angular/core';
import {Video} from '../../../shared/interfaces/video';
import {ModalController} from '@ionic/angular';
import {VideoService} from '../../../shared/services/video.service';
import {UserService} from '../../../shared/services/user.service';

@Component({
  selector: 'app-users-seen-current-video',
  templateUrl: './users-seen-current-video.component.html',
  styleUrls: ['./users-seen-current-video.component.scss'],
})
export class UsersSeenCurrentVideoComponent implements OnInit {
  @Input() video: Video;
  users = [];

  constructor(public modalCtrl: ModalController,
              private _video: VideoService,
              private _user: UserService) { }

  ngOnInit() {
    this._video.getUserSeenByVideo(this.video.key).subscribe(async users => {
      this.users = await this._video.db.populate(users, ['user']);
    })
  }



}
