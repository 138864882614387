import {Injectable} from '@angular/core';
import {map, take} from 'rxjs/operators';
import {Video} from '../interfaces/video';
import {Observable} from 'rxjs';
import {FirebaseDataService} from '../template-services/firebase-data.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import firebase from 'firebase';
import {User} from '../interfaces/user';
import DocumentReference = firebase.firestore.DocumentReference;

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  currentVideo: Video;
  videosArray: Video[] = [];
  query: any = {
    title: '',
    duration: 999999,
    categories: [],
    tools: [],
    difficulty: [],
  };

  constructor(public db: FirebaseDataService,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,) {
  }

  async getAllWell() {
    if (this.videosArray.length == 0) {
      this.videosArray = await this.db.colWithIds$<Video>('videos', ref => ref
        .where('trash', '==', false)
        .orderBy('createdAt', 'desc')).pipe(take(1)).toPromise();
    }
    return this.videosArray;
  }

  getAllCarousel() {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
      .where('isCarousel', '==', true)
      .orderBy('position', 'asc'));
  }

  getAll() {
    return this.db.colWithIds$<Video>('videos', ref => this.getQuery(ref))
      .pipe(map(videos => videos.filter(video => {
        if (this.query.tools.length > 0) {
          return video.title.toLowerCase().includes(this.query.title.toLowerCase()) && video.tools.some(tool => this.query.tools.some(queryTools => queryTools.key == tool.id));
        } else {
          return video.title.toLowerCase().includes(this.query.title.toLowerCase());
        }
      })));
  }

  getLastFive(): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .orderBy('createdAt', 'desc')
      .where('trash', '==', false).limit(5));
  }

  add(video: Video): void {
    this.afs.collection(`videos`).add(video);
  }

  set(videoKey: string, video: Video): void {
    this.afs.doc(`videos/${videoKey}`).set(video, {merge: true});
  }

  update(videoKey: string, video: Video): Promise<void> {
    return this.afs.doc(`videos/${videoKey}`).update(video);
  }

  get(videoKey: string): Observable<Video> {
    return this.db.doc$<Video>(`videos/${videoKey}`);
  }

  delete(videoKey: string): void {
    this.afs.doc(`videos/${videoKey}`).update({trash: true});
  }

  getReference(videoKey: string): DocumentReference {
    return this.db.doc(`videos/${videoKey}`).ref;
  }

  async uploadPicture(photo, videoKey: string, namePhoto: string) {
    const uploadRef = this.getStorageRef(videoKey, namePhoto);
    await uploadRef.put(photo);
    const url = await uploadRef.getDownloadURL().pipe(take(1)).toPromise();
    this.uploadImage(photo, videoKey, namePhoto);

    return url;
  }

  getStorageRef(id: string, photoName: string) {
    return this.storage.ref(`videos/${id}/${photoName}.jpeg`);
  }

  uploadImage(data, id, photoName: string) {
    return this.storage.upload(`videos/${id}/${photoName}.jpeg`, data);
  }

  createId(): string {
    return this.afs.createId();
  }

  getRelated(categories: DocumentReference[]): Observable<Video[]> {
    return this.db.colWithIds$<Video>('videos', ref => ref
      .where('trash', '==', false)
      .where('categories', 'array-contains-any', categories)
      .limit(5));
  }

  private getQuery(ref) {
    let query = ref
      .where('trash', '==', false);
    if (this.query.duration != null) {
      query = query.orderBy('duration')
        .where('duration', '<=', this.query.duration * 60);
    }

    if (this.query.difficulty != null) query = query.where('difficulty', '==', this.query.difficulty);

    if (this.query.categories.length > 0) {
      query = query.where('categories', 'array-contains-any', this.query.categories.map(category => this.db.getReference(`categories/${category.key}`)));
    }

    return query;
  }

  getByCategory(category): Promise<any[]> {
    return this.db.colWithIds$('videos', ref => ref
      .where('trash', '==', false)
      .where('categories', 'array-contains', this.db.getReference(`categories/${category}`))).pipe(take(1)).toPromise();
  }

  getUserSeenByVideo(videoKey: string): Observable<User[]> {
    return this.db.colGroup<User>('videoHistory', ref => ref
      .where('reference', '==', this.db.getReference(`videos/${videoKey}`))).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        data['user'] = this.db.getReference(`users/${a.payload.doc.ref.path.split('/')[1]}`);
        return data;
      })));
  }


}
