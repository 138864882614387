import {Injectable} from '@angular/core';
import {AlertController, ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController,
              private toastController: ToastController) {
  }

  async favoriteAlert(option: string) {
    let result;
    let alert = await this.alertController.create({
      cssClass: '',
      header: 'Favoritos',
      subHeader: '',
      message: option == 'add' ? `¿Estás seguro que quieres agregar este video a favoritos?` : `¿Estás seguro que quieres eliminar este video de favoritos?`,
      buttons: [{
        text: option == 'add' ? 'Agregar' : 'Eliminar',
        handler: () => {
          alert.dismiss(true);
          return false;
        }
      }, {
        text: 'Cancelar',
        handler: () => {
          alert.dismiss(false);
          return false;
        }
      }]
    });

    await alert.present();

    await alert.onDidDismiss().then((value) => {
      if (value !== null) {
        result = value.data;
      }
    });

    return result;
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: `${message}`,
      duration: 2000,
      color: 'primary'
    });
    await toast.present();
  }

  async confirm(title: string, text?: string, confirm: string = 'Iniciar sesion', cancel: string = 'Cancelar') {
    let result;
    let alert = await this.alertController.create({
      cssClass: '',
      header: 'Alerta',
      subHeader: title,
      message: text,
      buttons: [{
        text: confirm,
        handler: () => {
          alert.dismiss(true);
          return false;
        }
      },
        {
          text: cancel,
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        }]
    });

    await alert.present();

    await alert.onDidDismiss().then((value) => {
      if (value !== null) {
        result = value.data;
      }
    });

    return result;

  }

  async presentAlert(title: string, text: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      message: text,
      buttons: ['OK'],
      mode: 'ios'
    });

    await alert.present();
  }

  async presentAlertConfirm(title: string) {
    return new Promise(async (resolve, reject) => {
        const alert = await this.alertController.create({
          header: title,
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                reject();
              }
            },
            {
              text: 'Confirmar',
              handler: () => {
                resolve();
              }
            }
          ]
        });

        await alert.present();
      }
    );
  }

}
