import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {Observable} from 'rxjs';
import {IRecipe} from '../interfaces/IRecipe';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private db: FirebaseDataService) {
  }

  getAll(): Observable<IRecipe[]> {
    return this.db.colWithIds$('recipes', ref => ref.where('trash', '==', false));
  }
}
