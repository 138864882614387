// IMPORTS
import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection, AngularFirestoreCollectionGroup,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import {first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryFn} from '@angular/fire/firestore/interfaces';

type  DocPredicate<T> = string | AngularFirestoreDocument<T>;
type  CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  constructor(private db: AngularFirestore) {
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  colGroup<T>(ref: string, queryFn?: any): AngularFirestoreCollectionGroup<T> {
    return this.db.collectionGroup<T>(ref, queryFn);
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) return data;
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => a.payload.doc.data()) as T[])
    );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): Observable<T[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(
      map(docs => docs.map(a => {
        const data = a.payload.doc.data();
        if (data == null) return data;
        data['key'] = a.payload.doc.id;
        return data;
      }) as T[])
    );
  }

  colGroupWithIds$<T>(ref: string, queryFn?: QueryFn): Observable<T[]> {
    return this.colGroup(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => docs.map(a => {
          const data = a.payload.doc.data();
          data['key'] = a.payload.doc.id;
          return data;
        }) as T[])
      );
  }

  docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(map(doc => {
      const data = doc.payload.data() as T;
      if (data == null) return data;
      data['key'] = doc.payload.id;
      return data;
    }));
  }

  getReference(url: string): DocumentReference {
    return this.db.doc(url).ref;
  }

  async populate(items: any[], fields: string[], subFields: string[] = []) {
    return await Promise.all(items.map(async item => {
      for (let field of fields) {
        if (item[field] && item[field].path) {
          try {
            item[field] = await this.docWithId$(item[field].path).pipe(first()).toPromise();

            for (let subField of subFields) {
              if (item[field][subField]) {
                item[field][subField] = await this.docWithId$(item[field][subField].path).pipe(first()).toPromise();
              }
            }
          } catch (e) {
            console.error(`Hubo un error al obtener el documento del campo ${field.toUpperCase()}`, e);
          }
        }
      }
      return item;
    }));
  }
}
