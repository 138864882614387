import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Platform} from '@ionic/angular';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {AngularFireAuth} from '@angular/fire/auth';
import {take} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationPushService {

  constructor(private platform: Platform,
              private angularFirestore: AngularFirestore,
              private angularFireAuth: AngularFireAuth,
              private fcm: FCM,
              private router: Router) {
  }

  async getToken() {
    await this.platform.ready();

    if (!this.platform.is('cordova')) return;

    this.fcm.onTokenRefresh().subscribe((newToken) => {
      this.saveTokenToFirestore(newToken);
    });

    this.fcm.getToken().then(token => {
      this.saveTokenToFirestore(token);
    });
  }

  private async saveTokenToFirestore(token) {
    if (!token) {
      return;
    }
    const user = await this.angularFireAuth.authState.pipe(take(1)).toPromise();
    let userId = user.uid;

    if (this.platform.is('cordova')) {
      this.angularFirestore.doc(`users/${userId}/`).update({
        token
      });
    }
  }

  async listenNotifications() {
    if (!this.platform.is('cordova')) return;

    this.fcm.onNotification().subscribe(async data => {
      console.log(data);

      if (data.wasTapped) {
        this.router.navigateByUrl(`video-detail`);
      }
    });
    await this.fcm.requestPushPermission();
  }

}
