import {Component, OnInit} from '@angular/core';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import {take} from 'rxjs/operators';
import {VideoService} from '../../../shared/services/video.service';
import {Video} from '../../../shared/interfaces/video';
import {Router} from '@angular/router';
import {ObjectService} from '../../../shared/services/object.service';
import {Platform} from '@ionic/angular';
import {UserService} from '../../../shared/services/user.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-videos-history',
  templateUrl: './videos-history.component.html',
  styleUrls: ['./videos-history.component.scss'],
})
export class VideosHistoryComponent implements OnInit {
  videosHistory: any [] = [];
  videos: any;
  isWeb: boolean = false;
  videosSubscription: Subscription = new Subscription();

  constructor(private _videoHistory: VideoHistoryService,
              private _video: VideoService,
              private router: Router,
              private plt: Platform,
              private _user: UserService,
              private _alert: AlertService) {
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.plt.ready().then(() => {
      if(this.plt.is('desktop')) return this.isWeb = true
    });
    this._videoHistory.getAll().subscribe(data => {
      this.videosHistory = data;
    });

    this.videos = ObjectService.indexArray(await this._video.getAllWell(), 'key');
  }

  async goToVideoDetail(video: Video) {
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  getPercentTimeVideo(currentTime: number, duration: number): number {
    return currentTime / duration;
  }
}
