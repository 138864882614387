import {Pipe, PipeTransform} from '@angular/core';
import {isUndefined} from "util";

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], search: any, property?: string): any {
    let newArray = array.filter((item: any) => {
      if (property === null || property === 'null' || isUndefined(search) || search === null) {
        return true;
      }

      const last = Object.keys(item)[Object.keys(item).length - 1];

      if (isUndefined(property)) {
        for (const itemProperty in item) {
          if (item[itemProperty] != null) {
            let index = item[itemProperty].toString().toLowerCase().indexOf(search.toString().toLowerCase());
            if (index >= 0) {
              return true;
            }
            if (last === itemProperty && index === -1) {
              return false;
            }
          }
        }
      } else {
        if (item[property] != null) {
          let index = item[property].toString().toLowerCase().indexOf(search.toString().toLowerCase());
          if (index >= 0) {
            return true;
          }
          if (last === property && index === -1) {
            return false;
          }
        }
      }
    });

    return newArray;
  }
}
