import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../shared/template-services/auth.service';
import {LoadingController} from '@ionic/angular';
import {AlertService} from '../../../shared/template-services/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  forgotPasswordFG: FormGroup;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private loadingCtrl: LoadingController,
              private _auth: AuthService,
              private _alert: AlertService) {
  }

  ngOnInit() {
    this.forgotPasswordFG = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get formControls() {
    return this.forgotPasswordFG.controls;
  }

  async restorePassword() {
    if (this.forgotPasswordFG.valid) {
      await this.presentLoading();
      this._auth.restorePassword(this.forgotPasswordFG.value.email);
      this.loadingCtrl.dismiss();
      await this._alert.presentAlert('Correo enviado', 'Por favor, revise su bandeja de entrada.');
      this.router.navigateByUrl('auth/login');
    } else {
      this._alert.presentAlert('Correo invalido', 'Favor de ingresar su correo electrónico');

    }
  }

  goToLogin() {
    this.router.navigateByUrl('auth/login');
  }

  async presentLoading() {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando ...',
      spinner: 'circular'
    });
    return await loading.present();
  }
}
