import {Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {Video} from '../../../shared/interfaces/video';
import {Subscription} from 'rxjs';
import {VideoService} from '../../../shared/services/video.service';
import {IonContent, ModalController, Platform} from '@ionic/angular';
import {AuthService} from '../../../shared/template-services/auth.service';
import {AlertService} from '../../../shared/template-services/alert.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../../shared/services/category.service';
import {UserService} from '../../../shared/services/user.service';
import {Category} from '../../../shared/interfaces/category';
import {take} from 'rxjs/operators';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {MembershipService} from '../../../shared/services/membership.service';
import * as moment from 'moment';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProgramsService} from '../../services/programs.service';
import * as _ from 'lodash';
import {Location} from '@angular/common';

@Component({
  selector: 'app-all-videos',
  templateUrl: './all-videos.component.html',
  styleUrls: ['./all-videos.component.scss'],
  animations: [
    trigger('panelState', [
      state('closed', style({height: '43px', overflow: 'hidden'})),
      state('open', style({height: '*'})),
      transition('closed <=> open', animate('300ms ease-in-out')),
    ]),
  ],
})
export class AllVideosComponent implements OnInit, OnDestroy {
  @ViewChild('slideWithNav', {static: false})
  @ViewChild(IonContent, {static: false}) content: IonContent;
  @ViewChildren('categories') categoriesView;

  sliderFree = {
    zoom: false,
    slidesPerView: 2.2,
    centeredSlides: false,
    spaceBetween: -20
  };
  sliderFreeWeb = {
    zoom: false,
    slidesPerView: 5,
    centeredSlides: false,
    spaceBetween: -20
  };
  loaderVideos: boolean = true;
  videos: Video[] = [];
  videosSubscription: Subscription = new Subscription();
  videosCarouselSubscription: Subscription = new Subscription();
  videosCategoriesSubscription: Subscription = new Subscription();
  isUser: boolean = false;
  userSubscribe: Subscription = new Subscription();
  isWeb: boolean = false;
  videosCarousel: Video[] = [];
  videoCarousel: Video;
  categories: Category[];
  categoriesOrigin: Category[];
  videosCategories: Video[];
  memberships;
  tabSelected = 'method';
  favorites = [];
  history = [];
  folded = 'closed';
  programs = [];
  programSelected: any;
  isProgramSelected: boolean = false;

  constructor(private _video: VideoService,
              private platform: Platform,
              private modal: ModalController,
              private _auth: AuthService,
              private _alert: AlertService,
              public router: Router,
              private iab: InAppBrowser,
              private _category: CategoryService,
              private _membership: MembershipService,
              public modalController: ModalController,
              private _history: VideoHistoryService,
              private _favorite: FavoriteService,
              private _user: UserService,
              private plt: Platform,
              private _program: ProgramsService,
              private activatedRoute: ActivatedRoute,
              private location: Location) {
    activatedRoute.url.subscribe(data => {
      setTimeout(() => {
        this.content.scrollToPoint(0, +sessionStorage.getItem('scroll'), 0);

        this.categoriesView.forEach(category => {
          category.slideTo(+sessionStorage.getItem(category.el.id));
        });
      }, 100);
    });
  }

  async ngOnInit() {
    await this.plt.ready();

    this.isWeb = this.plt.is('desktop');

    const filteredPrograms = (await this._program.getAll().pipe(take(1)).toPromise())
      .filter((program: any) => !program.trash && this.verifyPrincipalProgram(program));

    this.programs = _.orderBy(filteredPrograms, 'position', 'asc');

    await this._auth.getCurrentUser();

    this.history = await this._history.getAll().pipe(take(1)).toPromise();
    this.favorites = await this._favorite.getAllByUser(this._auth.user.uid).pipe(take(1)).toPromise();


    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();

    this.userSubscribe = this._user.getCurrentUser().subscribe(async data => {
      this._user.user = data;
    });

    this.videosCategories = (await this._video.getAllWell())
      .map(video => ({
        ...video,
        isNew: moment(new Date()).diff(video.createdAt, 'day') <= 5,
        isFavorite: this.favorites.some(favorite => favorite.reference.id == video.key),
        isHistory: this.history.some(history => history.reference.id == video.key)
      }));

    this.categories = await this._category.getAll().pipe(take(1)).toPromise();

    this.categories = this.categories.map(category => ({
      ...category,
      videos: this.videosCategories.filter(video => video.categories.find(videoCategory => videoCategory.id == category.key))
    }));

    this.categoriesOrigin = [...this.categories];
    this.categories = this.categoriesOrigin.filter(category => category.program == 'method');

    this.videosCarouselSubscription = this._video.getAllCarousel()
      .subscribe(data => {
        this.videosCarousel = data;
        setTimeout(() => {
          let i = 0;
          setInterval(() => {
            this.videoCarousel = this.videosCarousel[i];
            i = (i + 1) % this.videosCarousel.length;
          }, 5000);
        }, 5000);
      });

    this.loadProgramSelected();
  }

  ngOnDestroy() {
    this.videosCarouselSubscription.unsubscribe();
    this.videosSubscription.unsubscribe();
    this.userSubscribe.unsubscribe();
    this.videosCategoriesSubscription.unsubscribe();
  }

  hasFreeTrialMembership(membershipKey: string): boolean {
    if (membershipKey == 'y1pI4abvw2QWZGyM7v0F') return true;

    const membership = this.memberships.find(membership => membership.key == membershipKey);

    return !!membership && membership.isFree;
  }

  async goToVideoDetail(video: Video) {
    if (this.hasFreeTrialMembership(this._user.user.membership.reference.id)) {
      let membership = video.memberships.findIndex(membership => membership.id == this._user.user.membership.reference.id);
      if (membership == -1 || this._user.user.membership.status == 0) {
        return this._alert.presentAlert('No cuentas con la membresía para ver este video', '');
      }
    }

    if (!await this._auth.isAuthenticated()) {
      this.isUser = true;
      this.signOutGuest();
    }
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  goToVideos(category: any) {
    this._video.query = {
      categories: [category],
      duration: 99999,
      title: '',
      tools: [],
      difficulty: null
    };
    this.router.navigateByUrl('user/videos');
  }

  goToCategory(category: any) {
    this._video.query = {
      categories: [category],
      duration: 99999,
      title: '',
      tools: [],
      difficulty: null
    };
    this.router.navigateByUrl('user/workout-serie-details/category/null');
  }

  checkIfNavDisable(slider, slideView) {
    this.checkIsNewVideos(slider, slideView);
    this.chekIsEnd(slider, slideView);
  }

  checkIsNewVideos(slider, slideView) {
    slideView.isBeginning().then((isTrue) => {
      slider.isBeginningSlide = isTrue;
    });
  }

  chekIsEnd(slider, slideView) {
    slideView.isEnd().then((isTrue) => {
      slider.isEndSlide = isTrue;
    });
  }

  handleChangeTab($event: any) {
    const tab = $event.detail.value;
    this.tabSelected = tab;
    this.categories = this.categoriesOrigin.filter(category => category.program == tab);
  }

  toggleFold() {
    this.folded = this.folded === 'open' ? 'closed' : 'open';
  }

  private verifyPrincipalProgram(program: any) {
    return program.code != 'calendar'
      && program.code != 'challenge'
      && program.code != 'Sin Programa';
  }

  openFile(fileUrl: string) {
    return this.iab.create(fileUrl, '_blank');
  }

  handleScroll(e) {
    sessionStorage.setItem('scroll', e.detail.scrollTop);
  }

  handleCategoryScroll(e, category: Category) {
    this.categoriesView.forEach(categoryView => {
      if (categoryView.el.id == category.key) {
        categoryView.getActiveIndex()
          .then(index => {
            sessionStorage.setItem(category.key, index);
          });
      }
    });
  }

  getImage(video: Video) {
    if (!!video.photoUrl) return video.photoUrl;
    return 'assets/icon/icon.png';
  }

  goToWorkoutSeries() {
    this.location.back();
  }

  loadProgramSelected() {
    this.activatedRoute.queryParams.subscribe(async (params: any) => {
      if (!params.program) return this.programSelected = 'method';

      this.isProgramSelected = true;
      this.programSelected = params.program;
      this.programs = this.programs.filter(program => program.code == this.programSelected);
      this.handleChangeTab({detail: {value: this.programSelected}});
    });
  }

  getProgramSelected() {
    return this.programs.find(program => program.code == this.programSelected);
  }

  downloadMaterial() {
    if (this.hasMembershipToDownloadMaterial()) return this.iab.create(this.getProgramSelected().welcomeFile, '_blank');

    this._alert.presentAlert('No cuentas con la membresía para descargar este material', '');
  }

  private hasMembershipToDownloadMaterial() {
    return !!this._user.user.membership
      && !!this._user.user.membership.reference
      && this._user.user.membership.reference.id != 'GkocmIhtAIzjCdAyrLtO'
      && this._user.user.membership.reference.id != '5gTBi9dlOszgfGLjsji3'
      && this._user.user.membership.reference.id != 'y1pI4abvw2QWZGyM7v0F';
  }
}
