import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PaymentHistoryService} from '../../services/payment-history.service';
import {PaymentType as paymentTypeEnum} from '../../../shared/enums/payment-type.enum';
import {PaymentType as paymentTypeLabel} from '../../label/payment-type.label';
import {Status as statusEnum} from '../../../shared/enums/status.enum';
import {Status as statusLabel} from '../../label/status.label';
import {UserService} from '../../../shared/services/user.service';
import {Membership} from '../../../shared/interfaces/membership';
import {ModalController} from '@ionic/angular';
import {PurchaseDetailsComponent} from '../../modals/purchase-details/purchase-details.component';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
})
export class PaymentHistoryComponent implements OnInit, OnDestroy {
  paymentsSubscription: Subscription = new Subscription();
  payments: any[] = [];
  paymentTypeEnum = paymentTypeEnum;
  paymentTypeLabel = paymentTypeLabel;
  statusEnum = statusEnum;
  statusLabel = statusLabel;

  constructor(private _history: PaymentHistoryService,
              private modalCtrl: ModalController) {
  }

  async ngOnInit() {
    this.paymentsSubscription = this._history.getAll(UserService.uid).subscribe(data => {
      this.payments = data;
    });
  }

  ngOnDestroy() {
    this.paymentsSubscription.unsubscribe();
  }

  async purchaseDetail(payment: Membership) {
    if (payment.paymentType == 0 && payment.status == 0) {
      const modal = await this.modalCtrl.create({
        component: PurchaseDetailsComponent,
        componentProps: {
          payment
        }
      });
      return modal.present();
    }
  }
}
