import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {VideoService} from '../../../shared/services/video.service';
import {Router} from '@angular/router';
import {CategoryService} from '../../../shared/services/category.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {take} from 'rxjs/operators';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {Favorite} from '../../../shared/interfaces/favorite';
import {UserService} from '../../../shared/services/user.service';
import {ObjectService} from '../../../shared/services/object.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() isFavorite: boolean;
  categories: any[] = [];
  bodyParts: any[] = [];
  tools: any[] = [];
  duration: number = 45;
  title: string = '';
  difficulty: string = '1';
  body = [
    'CORE/ABS AND GLUTES',
    'FULL BODY',
    'LOWER BODY',
    'QUICK ABS',
    'QUICK ARMS',
    'QUICK FULL BODY',
    'QUICK GLUTES',
    'QUICK LEGS',
    'UPPER BODY'
  ];
  isAdvancedSearch: boolean = false;

  constructor(private modal: ModalController,
              private _video: VideoService,
              private router: Router,
              private _category: CategoryService,
              private afs: AngularFirestore,
              private _user: UserService,
              private _favorite: FavoriteService) {
  }

  async ngOnInit() {
    this._category.getAll().subscribe(data => {
      this.categories = data.map(category => ({...category, selected: false}));
      this.categories = this.categories.filter(category => category.program != 'challenge')
      this.bodyParts = this.categories.filter(category => this.body.some(part => part === category.name));
      this.categories = this.categories.filter(category => !this.body.some(part => part === category.name));
    });

    this.tools = (await this._category.getAllTools().pipe(take(1)).toPromise()).map(tool => ({
      ...tool,
      selected: false
    }));
  }

  closeModal() {
    this.modal.dismiss();
  }

  filter() {
    let categories = [...this.bodyParts, ...this.categories];
    categories = categories.filter(category => category.selected);
    let toolsSelected = this.tools.filter(tool => tool.selected);
    if (this.isAdvancedSearch == false) {
      this._video.query = {
        categories: [],
        duration: null,
        title: this.title,
        tools: toolsSelected,
        difficulty: null
      };
    } else {


      this._video.query = {
        categories,
        duration: this.duration,
        title: this.title,
        tools: toolsSelected,
        difficulty: this.difficulty
      };
    }


    if (!this.isFavorite) {
      this.router.navigateByUrl('user/videos');
    }
    this.closeModal();
  }

  advancedSearch() {
    this.isAdvancedSearch = !this.isAdvancedSearch;
    console.log(this.isAdvancedSearch);
  }
}
