import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {ApplePay} from '@ionic-native/apple-pay/ngx';
import {Observable} from 'rxjs';
import {Membership} from '../../../shared/interfaces/membership';
import {MembershipService} from '../../../shared/services/membership.service';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../shared/template-services/auth.service';
import {UserService} from '../../../shared/services/user.service';
import {Coupon} from '../../../admin/interfaces/coupon';
import {CouponService} from '../../services/coupon.service';
import {PeriodOfTime} from '../../../shared/enums/period-of-time.enum';
import {PeriodType} from '../../../shared/enums/period-type.enum';
import {User} from '../../../shared/interfaces/user';
import {HistoryService} from '../../services/history.service';
import {DiscountType} from '../../../shared/enums/discount-type.enum';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Router} from '@angular/router';
import {PaymentComponent} from '../../modals/payment/payment.component';
import {IAPProduct, InAppPurchase2} from '@ionic-native/in-app-purchase-2/ngx';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import {Status} from '../../../shared/enums/status.enum';
import {SettingService} from '../../../shared/services/setting.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {PurchaseDetailsComponent} from "../../modals/purchase-details/purchase-details.component";


declare const stripe;
declare const paypal;

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss'],
})
export class MembershipsComponent implements OnInit {
  shippingMethods: any = [
    {
      identifier: 'Gratis',
      label: 'Gratis',
      detail: 'Indemediato',
      amount: 0
    }
  ];
  supportedNetworks: any = ['visa', 'amex', 'masterCard'];
  merchantCapabilities: any = ['3ds', 'debit', 'credit'];
  // merchantIdentifier: string = 'merchant.apple.test';
  merchantIdentifier: string = 'merchant.com.umana.umanaapp';
  currencyCode: string = 'MXN';
  countryCode: string = 'MX';
  billingAddressRequirement: any = ['name', 'email', 'phone'];
  shippingAddressRequirement: any = 'none';
  shippingType: string = 'service';
  memberships$: Observable<Membership[]>;
  coupons$: Observable<Coupon[]>;
  memberships: Membership[];
  coupons: Coupon[];
  membership$: Observable<Membership>;
  currentMembership: Membership;
  currentUser: any;
  user: any;
  currentDate: number = (new Date()).getTime();
  couponUsed = {indexCoupon: null, indexMembership: null, use: false, membershipKey: null};
  periodOfTime = PeriodOfTime;
  periodType = PeriodType;
  products: IAPProduct[] = [];
  typeMemberships: any;
  membership: any;
  isCardPay: boolean = false;
  isIos: boolean = true;
  paymentType = PaymentType;
  status = Status;
  setting: any;

  constructor(private applePay: ApplePay,
              public alertController: AlertController,
              private _membership: MembershipService,
              private _auth: AuthService,
              private _user: UserService,
              private _coupons: CouponService,
              private _history: HistoryService,
              private router: Router,
              private modalController: ModalController,
              private _alert: AlertService,
              private store: InAppPurchase2,
              public plt: Platform,
              private modalCtrl: ModalController,
              private ref: ChangeDetectorRef,
              private loadingCtrl: LoadingController,
              private _settings: SettingService,
              private iab: InAppBrowser) {
  }

  async ngOnInit() {
    this.setting = await this._settings.getAll().pipe(take(1)).toPromise()
    await this.getMemberships();
    await this.getCurrentUser();
    await this.getCoupons();
    this.isIos = this.plt.is('ios');
    this.isCardPay = !this.isIos;
    this.plt.ready().then(() => {
      // Only for debugging!
      this.store.verbosity = this.store.DEBUG;
      this.products = [];
      // this.registerProducts();
      if (this.plt.is('ios')) {
        this.setupListeners();

        // Get the real product information
        this.store.ready(() => {
          this.products = [];
          this.products = this.store.products;
          this.ref.detectChanges();
        });
      }
    });
  }

  registerProducts() {
    this.products = [];
    for (let membership of this.memberships) {
      this.store.register({
        id: membership.productId,
        type: this.store.NON_RENEWING_SUBSCRIPTION
      });
    }
    this.store.refresh();
  }

  setupListeners() {
    this.store.when('product')
      .approved(async (p: IAPProduct) => {
        // Handle the product deliverable
        let membership = this.memberships.find(membership => membership.productId == p.id);
        let membershipApple = {
          ...membership,
          paymentType: this.paymentType.STRIPE,
          status: this.status.PAID
        };
        let endTime = await this.contractMembership(membershipApple);
        p.expiryDate = new Date(endTime);
        this.ref.detectChanges();

        p.finish();
        // return p.verify();
      });
    // .verified((p: IAPProduct) => p.finish());
  }

  purchase(product: IAPProduct) {
    this.store.order(product).then(p => {
      // Purchase in progress!
    }, e => {
      this._alert.presentAlert('Failed', `Failed to purchase: ${e}`);
    });
  }

  restore() {
    this.store.refresh();
  }

  async checkApplePayValid() {
    await this.applePay.canMakePayments().then((message) => {
      console.log(message);
      this.presentAlert(message);

      // Apple Pay is enabled. Expect:
      // 'This device can make payments.'
    }).catch((error) => {
      console.log(error);
      this.presentAlert(error);
      // There is an issue, examine the message to see the details, will be:
      // 'This device cannot make payments.''
      // 'This device can make payments but has no supported cards'
    });
  }

  async payWithApplePay(membership) {
    let item = [{
      label: membership.name,
      amount: membership.price
    }];
    try {
      let order: any = {
        items: item,
        shippingMethods: this.shippingMethods,
        merchantIdentifier: this.merchantIdentifier,
        currencyCode: this.currencyCode,
        countryCode: this.countryCode,
        billingAddressRequirement: this.billingAddressRequirement,
        shippingAddressRequirement: this.shippingAddressRequirement,
        shippingType: this.shippingType,
        merchantCapabilities: this.merchantCapabilities,
        supportedNetworks: this.supportedNetworks
      };
      this.applePay.makePaymentRequest(order).then(() => {
        this.contractMembership(null);
        this.applePay.completeLastTransaction('success');
      }).catch((error) => {
        this.applePay.completeLastTransaction('failure');
        this.presentAlert(error);
      });

      // In real payment, this step should be replaced by an actual payment call to payment provider
      // Here is an example implementation:

      // MyPaymentProvider.authorizeApplePayToken(token.paymentData)
      //    .then((captureStatus) => {
      //        // Displays the 'done' green tick and closes the sheet.
      //        ApplePay.completeLastTransaction('success');
      //    })
      //    .catch((err) => {

      //        // Displays the 'failed' red cross.
      //        ApplePay.completeLastTransaction('failure');
      //    });

    } catch {
      // handle payment request error
      // Can also handle stop complete transaction but these should normally not occur
    }
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Alerta',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  async getMemberships() {
    await this.presentLoading('Cargando membresías');
    this.memberships$ = this._membership.getAll();
    this.memberships = await this.memberships$.pipe(take(1)).toPromise();
    await this.loadingCtrl.dismiss();
  }

  async getCurrentUser() {
    this.currentUser = await this._auth.loadFirebaseUser();
    this.user = await this._user.getSpecificUser(this.currentUser.uid);
    await this.getCurrentMembership();
  }

  async presentLoading(message: string): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'circles',
      mode: 'ios'
    });
    return await loading.present();
  }

  async getCurrentMembership() {
    this.membership$ = this._membership.get(this.user.membership.reference.id);
    this.currentMembership = await this.membership$.pipe(take(1)).toPromise();
  }

  async getCoupons() {
    this.coupons$ = this._coupons.getAll();
    this.coupons = await this.coupons$.pipe(take(1)).toPromise();
  }

  async contractMembership(membership, isInterbank = false) {

    let membershipReference = this._membership.getReference(membership.key);

    if (membership.periodOfTime == PeriodOfTime.RANGE) {
      await this._user.updateMembership(this.currentUser.uid, {
        endTime: membership.rangeEnd,
        reference: membershipReference,
        startTime: membership.rangeStart,
        status: isInterbank ? 0 : 1
      });
    }

    let endTime: number;
    if (membership.periodOfTime == PeriodOfTime.LIMITED) {
      if (membership.periodType == PeriodType.DAILY) {
        endTime = this.currentDate + (86400000 * membership.quantity);
        // if (membership.paymentType == this.paymentType.CARD) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
        // }
      }
      if (membership.periodType == PeriodType.WEEKLY) {
        endTime = this.currentDate + (604800000 * membership.quantity);
        // if (membership.paymentType == this.paymentType.CARD) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
        // }
      }
      if (membership.periodType == PeriodType.MONTHLY) {
        endTime = this.currentDate + (2592000000 * membership.quantity);
        // if (membership.paymentType == this.paymentType.CARD) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
        // }
      }
      if (membership.periodType == PeriodType.ANNUAL) {
        endTime = this.currentDate + (31536000000 * membership.quantity);
        // if (membership.paymentType == this.paymentType.CARD) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime,
          reference: membershipReference,
          startTime: this.currentDate,
          status: isInterbank ? 0 : 1
        });
        // }
      }
      if (membership.periodOfTime == PeriodOfTime.FOREVER) {
        // if (membership.paymentType == this.paymentType.CARD) {
        await this._user.updateMembership(this.currentUser.uid, {
          endTime: 95646466800000,
          reference: membershipReference,
          startTime: membership.rangeStart,
          status: isInterbank ? 0 : 1
        });
        // }
      }
    }

    //TODO EL USER VIENE UNDEFINED
    // Coupons
    // let usedCoupon = false;
    // if (!!this._user.user.pendingMembership && this._user.user.pendingMembership.couponUsed.membershipKey == membership.key) {
    //   usedCoupon = true;
    // }
    // await this.checkIsCouponUsed(usedCoupon);

    // await this._history.add(this.currentUser.uid, membershipHistory);
    let membershipHistory = {
      ...membership,
      createdAt: this.currentDate,
      endTime,
      status: isInterbank ? 0 : 1
    };

    await this._history.add(this.currentUser.uid, membershipHistory);

    this.currentMembership = membership;

    this._user.update(this.currentUser.uid, {pendingMembership: null} as User);

    if (isInterbank) {

      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: `El plan "${membership.name}" ha sido contratado con status PENDIENTE`,
        subHeader: 'Para ver detalles del status puede ir a la sección "Historial de Pagos" y seleccionar el pago pendiente',
        buttons: [
          {
            text: 'Aceptar',
            role: 'cancel',
            cssClass: 'modal-button-cancel',
            handler: async (blah) => {
              const modal = await this.modalCtrl.create({
                component: PurchaseDetailsComponent,
                componentProps: {
                  payment: membershipHistory
                }
              });
              await modal.present();

              const {data} = await modal.onWillDismiss();

              window.location.reload()
            }
          }
        ]
      });

      await alert.present();
    } else {
      this.presentAlert(`El plan "${membership.name}" ha sido contratado exitosamente`);

      return endTime;
    }
  }

  async checkIsCouponUsed(isBought: boolean) {
    if (isBought) {
      this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].used++;
      await this._coupons.update(this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon].key, this.coupons[this._user.user.pendingMembership.couponUsed.indexCoupon]);
    }

    if (this.couponUsed.indexMembership) {
      this.memberships[this.couponUsed.indexMembership].price = this.memberships[this.couponUsed.indexMembership].oldPrice;
      this.memberships[this.couponUsed.indexMembership].oldPrice = null;
    }

    this.couponUsed.indexMembership = null;
    this.couponUsed.indexCoupon = null;
    this.couponUsed.use = false;
    this.couponUsed.membershipKey = null;
  }

  async applyCoupon(membership, membershipIndex: number) {
    this.presentAlertInput(`Aplicar cupón para la membresía de ${membership.name}`, 'Ingresar código', 'Aplicar', membership, membershipIndex);
  }

  getIfMembershipItsOver(membership) {
    if (membership.periodOfTime != PeriodOfTime.RANGE) {
      return true;
    }
    return membership.rangeEnd > this.currentDate;

  }

  async openSession(membership: Membership) {
    if (this.presentAlert(`Estás seguro que quieres contratar la membresía "${membership.name}"`)) {
      const resp: any = await this._membership.createSession(membership.price, membership.name);
      await this._user.update(this._user.user.key, {
        pendingMembership: {
          membership,
          couponUsed: this.couponUsed,
          sessionId: resp.id
        }
      } as User);
      stripe.redirectToCheckout({sessionId: resp.id});
    }
  }

  // paypal(membership) {
  //   this.payPal.init({
  //     PayPalEnvironmentProduction: 'AZL4dVY7Fpa6Du7jQVMmaeA0tXpQvx2PgVVJEKeb7HkQy5rUfUtywyMZZYQ9MpkIbn94Iy-CxWNZVwT2',
  //     PayPalEnvironmentSandbox: 'AabOicDwhevDNNOlfox8cqsWPbS2Dh8m1wwULRvu-0NetAKou0DTcx8sPGhHucKITafCEJsiTBnsAxui'
  //   }).then(() => {
  //     // Environments: PayPalEnvironmentNoNetwork, PayPalEnvironmentSandbox, PayPalEnvironmentProduction
  //     this.payPal.prepareToRender('PayPalEnvironmentProduction', new PayPalConfiguration({}))
  //       .then(() => {
  //         let payment = new PayPalPayment(membership.price.toString(), 'MXN', membership.name, 'sale');
  //         this.payPal.renderSinglePaymentUI(payment).then((res) => {
  //           // Successfully paid
  //           // this._alert.presentAlert('Pago realizado con exito','')
  //           this.contractMembership(membership);
  //         }, () => {
  //           // this._alert.presentAlert('error en el pago', '');
  //           // Error or render dialog closed without being successful
  //         });
  //       }, () => {
  //         // Error in configuration
  //       });
  //   }, () => {
  //     // Error in initialization, maybe PayPal isn't supported or something else
  //   });
  // }

  async freeMembership(membership) {
    if (this.presentAlert(`Estas seguro de la membresía ${membership.name}`)) {
      await this.contractMembership(membership);
    }
  }

  async presentAlertMultipleButtons(membership: Membership) {
    let membershipCard = {
      ...membership,
      paymentType: this.paymentType.STRIPE,
      status: this.status.PAID
    };
    if (this.plt.is('ios')) {
      return this.paymentCard(membershipCard);
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Métodos de pagos',
      subHeader: 'Interbancario ó Tarjeta',
      buttons: [
        {
          text: 'Interbancario',
          cssClass: 'btns-modal-alert',
          handler: async () => {
            membership.paymentType = this.paymentType.INTERBANK;
            membership.status = this.status.PENDING;
            console.log(membership);
            await this.paymentInterbank(membership);
            // this.presentLoading('Solicitando membresía');
          }
        },
        , {
          text: 'Tarjeta',
          handler: () => {
            this.paymentCard(membershipCard);
          }
        },
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'modal-button-cancel',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }
      ]
    });

    await alert.present();
  }

  async paymentInterbank(membership): Promise<void> {
    const alert = await this.alertController.create({
      header: `¿Estás seguro de que quieres realizar la contratación de la membresía ${membership.name}?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Confirmar',
          handler: async () => {
            await this.contractMembership(membership, true);
          }
        }
      ]
    });

    await alert.present();
  }

  async createOrder() {

  }

  async paymentCard(membership) {
    const modal = await this.modalController.create({
      component: PaymentComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        membership
      }
    });

    modal.onDidDismiss().then(data => {
      if (!!data.data) {
        this.contractMembership((membership));
      }
    });
    return await modal.present();
  }

  async choosePaymentMethod() {
    const alert = await this.alertController.create({
      header: 'Elija el método de pago',
      buttons: [
        {
          text: 'Apple',
          handler: () => {
            this.isCardPay = false;
          }
        },
        {
          text: 'Tarjeta',
          handler: () => {
            this.isCardPay = true;
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertInput(title: string, textInput: string, textButton: string, membership?, membershipIndex?) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: 'code',
          type: 'text',
          placeholder: textInput
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: textButton,
          handler: async data => {
            if (!!data.code) {
              let indexCoupon = this.coupons.findIndex(coupon => coupon.code == data.code);
              if (indexCoupon == -1) {
                return this.presentAlert(`No se encuentra el código`);
              }
              let membershipReference = this.coupons[indexCoupon].memberships.find(m => m.id == membership.key);
              if (this.coupons[indexCoupon].endTime < this.currentDate) {
                return this.presentAlert(`Este código ya caducó`);
              }
              if (!membershipReference) {
                return this.presentAlert(`Este código no funciona en esta membresía`);
              }
              if (this.coupons[indexCoupon].used >= this.coupons[indexCoupon].available) {
                return this.presentAlert(`Este código ya alcanzo su límite de usos`);
              }
              if (this.couponUsed.membershipKey == membership.key) {
                return this.presentAlert(`Ya se utilizó un cupón para esta membresía`);
              }
              let discount;
              if (this.coupons[indexCoupon].type == DiscountType.PERCENTAGE) {
                discount = membership.price - (membership.price * (this.coupons[indexCoupon].quantityDiscount / 100));
              }
              if (this.coupons[indexCoupon].type == DiscountType.DIRECT) {
                discount = membership.price - this.coupons[indexCoupon].quantityDiscount;
              }

              let membershipWithNewPrice = {...membership};
              membershipWithNewPrice.price = discount;

              await this.checkIsCouponUsed(false);

              this.memberships[membershipIndex].oldPrice = membership.price;
              this.memberships[membershipIndex].price = discount;

              this.couponUsed.indexMembership = membershipIndex;
              this.couponUsed.indexCoupon = indexCoupon;
              this.couponUsed.use = true;
              this.couponUsed.membershipKey = membership.key;

              this.presentAlert('Se aplicó el cupón correctamente');

              // let message = `El precio original de la membresía es de ${membership.price} con el cupón ahora es ${membershipWithNewPrice.price}, estás seguro que quieres contratar la membresía de ${membership.name}`;
              // await this.contractMembership(membershipWithNewPrice);
            }
          }
        }
      ]
    });

    await alert.present();
  }

  backToHome() {
    this.router.navigateByUrl('user/home');
  }

  async openMembershipStripe() {
    await this._alert.presentAlertConfirm('Te enviaremos a una página en tu navegador ¿Deseas continuar?')
    this.iab.create('https://pagos.umana.com.mx', '_blank');
  }
}
