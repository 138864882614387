import {Component, OnInit} from '@angular/core';
import {Video} from '../../../shared/interfaces/video';
import {VideoHistoryService} from '../../../shared/services/video-history.service';
import {VideoService} from '../../../shared/services/video.service';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {ObjectService} from '../../../shared/services/object.service';
import {FavoriteService} from '../../../shared/services/favorite.service';
import {UserService} from '../../../shared/services/user.service';
import {Favorite} from '../../../shared/interfaces/favorite';
import {ModalController, Platform} from '@ionic/angular';
import {SearchComponent} from '../../modals/search/search.component';
import {AlertService} from '../../../shared/template-services/alert.service';
import {MembershipService} from '../../../shared/services/membership.service';
import {AuthService} from '../../../shared/template-services/auth.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
})
export class FavoritesComponent implements OnInit {
  favorites: Favorite[] = [];
  allFavorites: Favorite[] = [];
  videos: any;
  isWeb: boolean = false;
  memberships: any[] = [];

  constructor(private _videoHistory: VideoHistoryService,
              private _favorite: FavoriteService,
              private _user: UserService,
              private _video: VideoService,
              private router: Router,
              private plt: Platform,
              public modal: ModalController,
              private _membership: MembershipService,
              private _auth: AuthService,
              public modalController: ModalController,
              private _alert: AlertService) {
  }

  async ngOnInit() {
    this.plt.ready().then(() => {
      if (this.plt.is('desktop')) {
        return this.isWeb = true;
      }
    });
    this._favorite.getAllByUser(this._user.user.key).subscribe(favorites => this.favorites = this.allFavorites = favorites)
    this.memberships = await this._membership.getAll().pipe(take(1)).toPromise();
    this.videos = ObjectService.indexArray(await this._video.getAllWell(), 'key');
  }

  hasFreeTrialMembership(membershipKey: string): boolean {
    if (membershipKey == 'y1pI4abvw2QWZGyM7v0F') return true;

    const membership = this.memberships.find(membership => membership.key == membershipKey);

    return !!membership && membership.isFree;
  }

  async goToVideoDetail(video: Video) {
    if (this.hasFreeTrialMembership(this._user.user.membership.reference.id)) {
      let membership = video.memberships.findIndex(membership => membership.id == this._user.user.membership.reference.id);
      if (membership == -1 || this._user.user.membership.status == 0) {
        return this._alert.presentAlert('No cuentas con la membresía para ver este video', '');
      }
    }

    if (!await this._auth.isAuthenticated()) {
      this.signOutGuest();
    }


    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  async signOutGuest() {
    if (!await this._auth.isAuthenticated()) {
      if (await this._alert.confirm('Esta es una función para usuarios registrados', 'Iniciar sesión', 'Cancelar')) {
        localStorage.setItem('isLogin', 'false');
        this.router.navigateByUrl('auth');
        this.modalController.dismiss();
      }
      return;
    }
  }

  async openSearch() {
    const modal: any = await this.modal.create({
      component: SearchComponent,
      componentProps: {
        isFavorite: true
      }
    });

    modal.onWillDismiss().then(() => {
      this.favorites = this.allFavorites.filter(favorite => {
        const video = this.videos[favorite.reference.id];

        if (!video) {
          return false;
        }

        let findByCategory = !!this._video.query.categories.find(category => !!video.categories.find(videoCategory => videoCategory.id == category.key));
        const findByDuration = video.duration <= this._video.query.duration * 60;
        let findByTitle = video.title.replaceAll(' ', '').toLowerCase().includes(this._video.query.title.replaceAll(' ', '').toLowerCase());

        if (!this._video.query.title) {
          findByTitle = true;
        }
        if (this._video.query.categories.length == 0) {
          findByCategory = true;
        }

        return findByCategory && findByDuration && findByTitle;
      });
    });

    return await modal.present();
  }

}
