import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {appRoutes} from './app.routing';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {environment} from '../environments/environment';
import {VideoPlayer} from '@ionic-native/video-player/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {ApplePay} from '@ionic-native/apple-pay/ngx';
import {InAppPurchase2} from '@ionic-native/in-app-purchase-2/ngx';
import {HttpClientModule} from '@angular/common/http';
import {Stripe} from '@ionic-native/stripe/ngx';
import {SharedModule} from './shared/shared.module';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MyRouteReuseStrategy} from './app-route-reuse-strategy';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      useHash: true
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    VideoPlayer,
    InAppBrowser,
    FCM,
    Camera,
    {provide: RouteReuseStrategy, useClass: MyRouteReuseStrategy},
    ApplePay,
    InAppPurchase2,
    Stripe,
    Clipboard,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
