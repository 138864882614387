import {Component, Input, OnInit} from '@angular/core';
import {PaymentType} from '../../../shared/enums/payment-type.enum';
import {AlertService} from '../../../shared/template-services/alert.service';
import {Clipboard} from '@ionic-native/clipboard/ngx';
import {LoadingController, ModalController, Platform, ToastController} from '@ionic/angular';
import {Membership} from '../../../shared/interfaces/membership';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {PaymentHistoryService} from '../../services/payment-history.service';
import {UserService} from '../../../shared/services/user.service';
import {Router} from '@angular/router';
import {SettingService} from '../../../shared/services/setting.service';
import {Setting} from '../../../shared/interfaces/setting';
import {Subscription} from 'rxjs';
import {User} from '../../../shared/interfaces/user';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.scss'],
})
export class PurchaseDetailsComponent implements OnInit {
  @Input() payment: Membership;
  paymentTypeEnum = PaymentType;
  picture;
  setting: any;
  settingSubscribe: Subscription = new Subscription();
  options: CameraOptions = {
    quality: 30,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
    sourceType: 0,
  };
  profilePicture: any;
  isDesktop: boolean = false;

  constructor(private clipboard: Clipboard,
              public toastController: ToastController,
              public modalCtrl: ModalController,
              private camera: Camera,
              public loadingCtrl: LoadingController,
              private _alert: AlertService,
              private _history: PaymentHistoryService,
              private router: Router,
              private modalController: ModalController,
              private _settings: SettingService,
              private platform: Platform) {
  }

  async ngOnInit() {
    await this.platform.ready();
    this.isDesktop = !this.platform.is('cordova');
    this.setting = await this._settings.getAll().pipe(take(1)).toPromise();
  }

  ngOnDestroy() {
    this.settingSubscribe.unsubscribe();
  }

  async copyToClipboard(text) {
    this.isDesktop
      ? navigator.clipboard.writeText(text)
      : await this.clipboard.copy(text);

    const toast = await this.toastController.create({
      message: 'Copiado!',
      duration: 2000
    });
    await toast.present();
  }

  async presentLoading(): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message: 'Cargando ...',
      spinner: 'circular'
    });
    return await loading.present();
  }

  openFile() {
    this.camera.getPicture(this.options)
      .then(resp => {
        console.log(resp);
        this.changeImage(resp);
      })
      .catch(err => {
        console.log(`:: CAMERA ERROR ${err}`);
      });
  }

  async changeImage(image) {
    this.presentLoading();
    this.profilePicture = 'data:image/jpeg;base64,' + image;
    let ticketURL = await this._history.uploadTicket(this.profilePicture, this.payment.key);
    this._history.updatePayment(this.payment.key, {ticketURL} as Membership);
    this.loadingCtrl.dismiss();
    this._alert.presentAlert('Tu comprobante será revisado', '');
    this.router.navigateByUrl('payment-history');
    this.modalController.dismiss();
  }
}


