import {Injectable} from '@angular/core';
import {FirebaseDataService} from '../../shared/template-services/firebase-data.service';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Achievement, UserAchievement} from '../interfaces/achievement';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {UserService} from '../../shared/services/user.service';
import {CompletedGoalComponent} from '../pages/completed-goal/completed-goal.component';
import {ModalController} from '@ionic/angular';
import {VideoService} from '../../shared/services/video.service';

@Injectable({
  providedIn: 'root'
})
export class AchievementService extends FirebaseDataService {
  wasShownCompletedGoal: boolean;

  constructor(private _db: AngularFirestore,
              private modalController: ModalController,
              private _video: VideoService) {
    super(_db);
  }

  getAll(): Observable<Achievement[]> {
    return this.colWithIds$(`achievements`);
  }

  getMyAchievements(userKey: string): Observable<UserAchievement[]> {
    return this.colWithIds$(`users/${userKey}/achievements`);
  }

  getUserAchievement(achievementKey: string): Promise<UserAchievement> {
    return this.docWithId$<UserAchievement>(`users/${UserService.uid}/achievements/${achievementKey}`).pipe(take(1)).toPromise();
  }

  updateUserAchievement(achievementKey: string, achievement): Promise<void>{
    delete achievement.key;
    return this._db.doc(`users/${UserService.uid}/achievements/${achievementKey}`).update(achievement);
  }

  addUserAchievement(achievementKey: string, achievement): Promise<void>{
    return this._db.doc(`users/${UserService.uid}/achievements/${achievementKey}`).set(achievement);
  }

  async getAchievementByRef(ref: DocumentReference): Promise<Achievement> {
    return _.first(await (this.colWithIds$<Achievement>(`achievements`, queryRef => queryRef.where('ref', '==', ref)).pipe(take(1)).toPromise()));
  }

  async handleCompletedGoal(achievement, video){
    const myAchievement: UserAchievement = await this.getUserAchievement(achievement.key);
    let completedGoal;

    if (myAchievement) {
      myAchievement.videos.push(video.key);
      myAchievement.videos = _.uniq(myAchievement.videos);

      completedGoal = achievement.goals
        .trim()
        .replace(/\s/g, '')
        .split(',')
        .find(goal => goal == myAchievement.videos.length.toString()
          && !myAchievement.completedGoals.some(g => g.goal == goal));

      if (completedGoal) {
        myAchievement.completedGoals.push({
          goal: completedGoal,
          date: new Date().getTime()
        });

        myAchievement.completedGoals = _.uniqBy(myAchievement.completedGoals, 'goal');
      }

      await this.updateUserAchievement(myAchievement.key, myAchievement);
    } else {
      completedGoal = achievement.goals
        .trim()
        .replace(/\s/g, '')
        .split(',')
        .find(goal => goal == '1');

      await this.addUserAchievement(achievement.key, {
        videos: [video.key],
        completedGoals: completedGoal
          ? [{
            goal: completedGoal,
            date: new Date().getTime()
          }]
          : []
      });
    }

    if (completedGoal) {
      await this.showCompletedGoal(completedGoal, achievement);
    }
  }

  async showCompletedGoal(goal, achievement) {
    if (this.wasShownCompletedGoal) return;

    this.wasShownCompletedGoal = true;

    const modal = await this.modalController.create({
      component: CompletedGoalComponent,
      componentProps: {
        goal,
        achievement
      }
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  async handleGoalsByProgram(achievement, program, video, categories) {
    const allCategoriesByProgram = categories.filter(c => c.program == program);
    const myAchievement: UserAchievement = await this.getUserAchievement(achievement.key);

    if (myAchievement) {
      let allVideos = [];

      for (const category of allCategoriesByProgram) {
        const categoryVideos = await this._video.getByCategory(category.key);
        allVideos = _.uniqBy(
          [
            ...allVideos,
            ...(categoryVideos || [])
          ],
          'key'
        );
      }

      myAchievement.videos.push(video.key);
      myAchievement.videos = _.uniq(myAchievement.videos);

      if (myAchievement.videos.length == allVideos.length && !myAchievement.completedGoals.length) {
        myAchievement.completedGoals.push({
          goal: allVideos.length.toString(),
          date: new Date().getTime()
        });

        this.showCompletedGoal(allVideos.length.toString(), achievement);
      }

      await this.updateUserAchievement(myAchievement.key, myAchievement);
    } else {
      await this.addUserAchievement(achievement.key, {
        videos: [video.key],
        completedGoals: []
      });
    }
  }

}
