import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../shared/template-services/auth.service";
import {AlertService} from "../../../shared/template-services/alert.service";

@Component({
  selector: 'app-choose-auth',
  templateUrl: './choose-auth.component.html',
  styleUrls: ['./choose-auth.component.scss'],
})
export class ChooseAuthComponent implements OnInit {

  constructor(private router: Router,
              private _auth: AuthService,
              private _alert: AlertService) {
  }

  ngOnInit() {
  }

  redirectToLogin() {
    this.router.navigateByUrl('auth/login')
  }

  redirectToRegister() {
    this.router.navigateByUrl('auth/register')
  }

  async submit(): Promise<void> {
    let email = 'guest@umana.com'
    let password = 'guestumana123'

    const user = await this._auth.loginWithPassword(email, password)
      .catch(err => {
        this._alert.presentAlert(AuthService.getMessageError(err.code), '')
      });

    if (!user) {
      return;
    }
    window.location.reload();
  }
}
