import {Pipe, PipeTransform} from '@angular/core';
import {RecipeType} from '../enums/recipe-type.enum';

@Pipe({
  name: 'recipeTypeLabel'
})
export class RecipeTypeLabelPipe implements PipeTransform {

  transform(value: number): string {
    switch (+value) {
      case RecipeType.RECIPE:
        return 'Recetas';
      case RecipeType.GUIDES:
        return 'Guías cortas';
      case RecipeType.PURCHASE_LINK:
        return 'Links de compras';
    }
  }

}
