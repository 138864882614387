import {Injectable} from '@angular/core';
import {FirebaseDataService} from 'src/app/shared/template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class WelcomeSlideService {

  constructor(private db: FirebaseDataService) {
  }

  getAll() {
    return this.db.colWithIds$('welcomeSlides', ref => ref.where('trash', '==', false));
  }
}
