import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import { DocPipe } from './pipes/doc.pipe';
import { SafeDomainPipe } from './pipes/safe-domain.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {OrderbyPipe} from './pipes/orderby.pipe';

@NgModule({
  declarations: [
    DocPipe,
    SafeDomainPipe,
    FilterPipe,
    NotFoundComponent,
    OrderbyPipe
  ],
  exports: [
    DocPipe,
    SafeDomainPipe,
    FilterPipe,
    NotFoundComponent,
    OrderbyPipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})

export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}
