import { Injectable } from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Membership} from '../../shared/interfaces/membership';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private afs: AngularFirestore) {
  }

  getAll(): Observable<Membership[]> {
    return this.afs.collectionGroup<Membership>(`history`).valueChanges();
  }

  getLatest(): Observable<Membership[]> {
    return this.afs.collectionGroup<Membership>(`history`, ref => ref
      .orderBy('createdAt', 'desc').limit(5)).snapshotChanges().pipe(map(actions => actions.map(a => {
      return {
        ...a.payload.doc.data(),
        key: a.payload.doc.id,
        userKey: a.payload.doc.ref.parent.parent.id
      }
    })));
  }

  getAllByUser(userKey: string): Observable<Membership[]> {
    return this.afs.collection<Membership>(`users/${userKey}/history`, ref => ref
      .where('trash', '==', false)).valueChanges();
  }

  get(userKey: string, membershipKey: string) {
    return this.afs.doc(`users/${userKey}/history/${membershipKey}`).valueChanges();
  }

  async add(userKey: any, membership: any): Promise<any> {
    return await this.afs.collection<Membership>(`users/${userKey}/history`).add(membership);
  }

  async delete(userKey: string, membershipKey: string): Promise<void> {
    return await this.afs.doc<Membership>(`users/${userKey}/history/${membershipKey}`).update({trash: true});
  }

  update(userKey: any, membershipKey: any, membership: any) {
    this.afs.doc(`users/${userKey}/history/${membershipKey}`).update(membership);
  }
}
