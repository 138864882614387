import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MembershipsComponent} from './pages/memberships/memberships.component';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserComponent} from './user.component';
import {UserRoutes} from './user-routing.module';
import {PaymentHistoryComponent} from './pages/payment-history/payment-history.component';
import {VideoDetailComponent} from './pages/video-detail/video-detail.component';
import {SharedModule} from '../shared/shared.module';
import {CategoriesComponent} from './pages/categories/categories.component';
import {VideosHistoryComponent} from './pages/videos-history/videos-history.component';
import {FavoritesComponent} from './pages/favorites/favorites.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {UpdateProfileComponent} from './modals/update-profile/update-profile.component';
import {ChangePasswordComponent} from './modals/change-password/change-password.component';
import {ChangeEmailComponent} from './modals/change-email/change-email.component';
import {MembershipProfileComponent} from './pages/membership-profile/membership-profile.component';
import {MembershipExpiredComponent} from './modals/membership-expired/membership-expired.component';
import {VideoListComponent} from './pages/video-list/video-list.component';
import {HomeComponent} from './pages/home/home.component';
import {SearchComponent} from './modals/search/search.component';
import {AddCardPaymentComponent} from './modals/add-card-payment/add-card-payment.component';
import {PaymentComponent} from './modals/payment/payment.component';
import {PurchaseDetailsComponent} from './modals/purchase-details/purchase-details.component';
import {CalendarComponent} from './pages/calendar/calendar.component';
import {ChallengesComponent} from './pages/challenges/challenges.component';
import {RecipesComponent} from './pages/recipes/recipes.component';
import {RecipeTypeLabelPipe} from './pipes/recipe-type-label.pipe';
import {CancellationOpinionComponent} from './modals/cancellation-opinion/cancellation-opinion.component';
import {WorkoutSeriesComponent} from './pages/workout-series/workout-series.component';
import {WorkoutSerieDetailsComponent} from './pages/workout-serie-details/workout-serie-details.component';
import {VideoPlayerComponent} from './modals/video-player/video-player.component';
import {AllVideosComponent} from './pages/all-videos/all-videos.component';
import {MainPageTabsComponent} from './pages/main-page-tabs/main-page-tabs.component';
import {ProgramsPage} from './pages/programs/programs.page';
import {MyAchievementsComponent} from './pages/my-achievements/my-achievements.component';
import {CompletedGoalComponent} from './pages/completed-goal/completed-goal.component';
import {CommentsComponent} from "./modals/comments/comments.component";
import {UsersSeenCurrentVideoComponent} from './modals/users-seen-current-video/users-seen-current-video.component';

@NgModule({
  declarations: [
    UserComponent,
    MembershipsComponent,
    PaymentHistoryComponent,
    VideoDetailComponent,
    CategoriesComponent,
    VideosHistoryComponent,
    FavoritesComponent,
    ProfileComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    MembershipProfileComponent,
    MembershipExpiredComponent,
    VideoListComponent,
    HomeComponent,
    SearchComponent,
    AddCardPaymentComponent,
    PaymentComponent,
    PurchaseDetailsComponent,
    CalendarComponent,
    ChallengesComponent,
    RecipesComponent,
    RecipeTypeLabelPipe,
    CancellationOpinionComponent,
    WorkoutSeriesComponent,
    WorkoutSerieDetailsComponent,
    VideoPlayerComponent,
    AllVideosComponent,
    MainPageTabsComponent,
    ProgramsPage,
    MyAchievementsComponent,
    CompletedGoalComponent,
    CommentsComponent,
    UsersSeenCurrentVideoComponent
  ],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    RouterModule.forChild(UserRoutes),
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
  ]
})
export class UserModule {
}
