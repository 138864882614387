import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Video} from '../../../shared/interfaces/video';
import {ModalController} from '@ionic/angular';
import {WorkoutSerieService} from '../../services/workout-serie.service';
import {UserService} from 'src/app/shared/services/user.service';
import {WorkoutSerie} from '../../interfaces/workout-serie';
import {AlertService} from 'src/app/shared/template-services/alert.service';
import {Achievement, UserAchievement} from '../../interfaces/achievement';
import {AchievementService} from '../../services/achievement.service';
import * as _ from 'lodash';
import {CompletedGoalComponent} from '../../pages/completed-goal/completed-goal.component';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: any;
  @Input() video: Video;
  @Input() workoutSerie: WorkoutSerie;
  @Input() myCurrentWorkoutSerie: any;
  @Input() isWorkoutSeries: boolean = true;
  timeInterval: any;
  myCurrentWorkoutVideo: any;
  wasShownCompletedGoal: boolean;

  constructor(public modalCtrl: ModalController,
              private _workoutSerie: WorkoutSerieService,
              private _alert: AlertService,
              private _achievement: AchievementService) {
  }

  ngOnInit() {
    this.myCurrentWorkoutVideo = this.myCurrentWorkoutSerie.videos.find(({video}) => video.id === this.video.key);
    if (!this.myCurrentWorkoutVideo) {
      this._alert.presentAlert('Error', 'Video not found');
      return this.modalCtrl.dismiss();
    }

    this.saveVideoCurrentTime();
  }

  ngAfterViewInit(): void {
    this.videoPlayer.nativeElement.addEventListener('ended', async () => {
      await this.closeVideoPlayer(true);
    });

    this.videoPlayer.nativeElement.currentTime = this.myCurrentWorkoutVideo.currentTime;
  }

  async ngOnDestroy() {
    await this.closeVideoPlayer();
  }

  saveVideoCurrentTime() {
    this.timeInterval = setInterval(async () => {
      await this.updateMyWorkoutSerieCurrentTime();
    }, 300000);
  }

  async closeVideoPlayer(isEndedVideo?: boolean) {
    clearInterval(this.timeInterval);
    await this.updateMyWorkoutSerieCurrentTime(!!isEndedVideo);
    await this.modalCtrl.dismiss();
  }

  async updateMyWorkoutSerieCurrentTime(isEndedVideo?: boolean) {
    if (!this.isWorkoutSeries) return;

    await this._workoutSerie.updateMyWorkoutSerie(UserService.uid, this.workoutSerie.key, {
      videos: this.myCurrentWorkoutSerie.videos.map((videoItem) => {
          if (videoItem.video.id !== this.video.key) return videoItem;

          return {
            ...videoItem,
            lastView: new Date().getTime(),
            currentTime: isEndedVideo ? 0 : this.videoPlayer.nativeElement.currentTime,
            isSeen: this.myCurrentWorkoutVideo.isSeen ? this.myCurrentWorkoutVideo.isSeen : this.video.duration - this.videoPlayer.nativeElement.currentTime < 10
          };
        }
      )
    });

    if (!this.verifyCompletedVideo()) return;

    const achievement: Achievement = await this._achievement.getAchievementByRef(this._achievement.getReference(`workoutSeries/${this.workoutSerie.key}`));

    if (!achievement) return;
    if (!achievement.visible) return;

    await this._achievement.handleCompletedGoal(achievement, this.video);
  }

  verifyCompletedVideo() {
    return (this.videoPlayer.nativeElement.currentTime / this.video.duration) * 100 >= 90;
  }
}
