import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/template-services/auth.service';

@Component({
  selector: 'app-main-page-tabs',
  templateUrl: './main-page-tabs.component.html',
  styleUrls: ['./main-page-tabs.component.scss'],
})
export class MainPageTabsComponent implements OnInit {
  isUser: boolean;

  constructor(private _auth: AuthService) {
  }


  ngOnInit() {
    this._auth.$isUser.subscribe(isUser => {
      this.isUser = isUser;
    });
  }
}
