import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {UserService} from '../../shared/services/user.service';
import {ModalController} from '@ionic/angular';
import {MembershipExpiredComponent} from '../modals/membership-expired/membership-expired.component';
import {AuthService} from '../../shared/template-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService,
              private _auth: AuthService,
              public modalController: ModalController) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<any> {
    await this._auth.loadFirebaseUser();
    await this._user.loadUser(this._auth.user.uid);

    if (!this._user.user.membership || new Date().getTime() > this._user.user.membership.endTime) {
      const modal = await this.modalController.create({
        component: MembershipExpiredComponent,
        swipeToClose: false,
        keyboardClose: false,
        backdropDismiss: false,
        componentProps: {
          user: {...this._user.user},
          endTime: !!this._user.user.membership ? this._user.user.membership.endTime : 0
        }
      });

      return await modal.present();
    }

    return true;
  }
}
