import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AuthService} from '../../shared/template-services/auth.service';
import {UserService} from '../../shared/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router,
              private _auth: AuthService,
              private _user: UserService) {
  }

  async canActivate(next: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    await this._auth.loadFirebaseUser();
    if (!this._auth.user) return true;
    return this.router.navigateByUrl('user');
  }

}
