import {Component, OnDestroy, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {Category} from '../../../shared/interfaces/category';
import {CategoryService} from '../../../shared/services/category.service';
import {Router} from '@angular/router';
import {VideoService} from '../../../shared/services/video.service';
import {Video} from '../../../shared/interfaces/video';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  videos: Video[];
  categories: Category[];
  sliderOptions = {
    slidesPerView: 2
  };
  videosSubscription: Subscription = new Subscription();

  constructor(private _category: CategoryService,
              private router: Router,
              private _video: VideoService,
              public _user: UserService) {
  }

  async ngOnInit() {
    this.categories = await this._category.getAll().pipe(take(1)).toPromise();
    this.videosSubscription = this._video.getAll().subscribe(data => {
      this.videos = data;

      this.videos = this.videos.map(video => ({
        ...video,
        categories: this.formatCategories(video.categories),
        memberships: this.formatMemberships(video.memberships),
      }));

      this.categories = this.categories.map(category => ({
        ...category,
        videos: this.videos.filter(video => video.categories.includes(category.key) && !!this._user.user.membership && video.memberships.includes(this._user.user.membership.reference.id))
      }));
    });
  }

  ngOnDestroy() {
    this.videosSubscription.unsubscribe();
  }

  goToVideos(categoryName): void {
    this.router.navigateByUrl(`user/memberships/${categoryName}`);
  }

  private formatCategories(categories: any) {
    return categories.map(category => category.id);
  }

  async goToVideoDetail(video) {
    this._video.currentVideo = video;
    await this.router.navigateByUrl(`user/video-detail`);
  }

  private formatMemberships(memberships: any[]) {
    return memberships.map(membership => membership.id);
  }
}
