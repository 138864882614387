import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkoutSerie } from '../../interfaces/workout-serie';
import { VideoService } from '../../../shared/services/video.service';
import { first, map} from 'rxjs/operators';
import { WorkoutSerieService } from '../../services/workout-serie.service';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';
import { Membership } from '../../../shared/interfaces/membership';
import { MembershipService } from '../../../shared/services/membership.service';

@Component({
  selector: 'app-workout-series',
  templateUrl: './workout-series.component.html',
  styleUrls: ['./workout-series.component.scss']
})
export class WorkoutSeriesComponent implements OnInit, OnDestroy {
  workoutSeriesSubscription: Subscription = new Subscription();
  workoutSeries: WorkoutSerie[] = [];
  userMembership: Membership;

  constructor(
    private router: Router,
    private _workingOutSerie: WorkoutSerieService,
    private _video: VideoService,
    private _user: UserService,
    private _membership: MembershipService
  ) {}

  async ngOnInit() {
    await this.setUserMembership();
    this.listenForWorkoutSeries();
  }

  ngOnDestroy() {
    this.workoutSeriesSubscription.unsubscribe();
  }

  listenForWorkoutSeries() {
    this.workoutSeriesSubscription = this._workingOutSerie
      .getAll()
      .pipe(
        map(workoutSeries =>
          workoutSeries.filter(workoutSerie =>
            workoutSerie.memberships.some(
              membership =>
              this.validateIsMembershipFreeTrial(this.userMembership) || membership.id == this.userMembership.key
            )
          )
        )
      )
      .subscribe(async (workoutSeries: any[]) => {
        this.workoutSeries = await this.getFormattedWorkingOutSerie(workoutSeries);
      });
  }

  async getFormattedWorkingOutSerie(workoutSeries: any[]) {
    return await Promise.all(
      workoutSeries.map(async workingOutSerie => ({
        ...workingOutSerie,
        videos: await Promise.all(
          workingOutSerie.videos.map(
            async video =>
              await this._video
                .get('id' in video ? video.id : video.key)
                .pipe(first())
                .toPromise()
          )
        )
      }))
    );
  }

  goToWorkoutSerieDetails({key: workoutSerieKey}: WorkoutSerie) {
    this.router.navigateByUrl(`/user/workout-serie-details/workout-series/${workoutSerieKey}`);
  }

  async setUserMembership() {
    const userMembership = (<any>this._user.user).membership;
    if (!userMembership) return;
    this.userMembership = await this._membership.get(userMembership.reference.id).pipe(first()).toPromise();
  }

  validateIsMembershipFreeTrial(membership) {
    return !!membership && membership.isFree && membership.key != 'y1pI4abvw2QWZGyM7v0F';
  }
}
