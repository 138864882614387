import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {Observable, ReplaySubject} from 'rxjs';
import {Membership} from '../interfaces/membership';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, take} from 'rxjs/operators';
import {FirebaseDataService} from '../template-services/firebase-data.service';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  private fetched: boolean;
  private value$: ReplaySubject<any> = new ReplaySubject();

  constructor(private db: FirebaseDataService,
              private afs: AngularFirestore,
              private http: HttpClient) {

  }

  getAll(): Observable<Membership[]> {
    if (!this.fetched) {
      this.db.colWithIds$<Membership>('memberships', ref => ref
        .where('trash', '==', false)
        .orderBy('position', 'asc'))
        .pipe(
          map(mem => mem
            .filter(item => item.key != 'y1pI4abvw2QWZGyM7v0F')))
        .subscribe(data => {
          this.fetched = true;
          this.value$.next(data);
        });
    }

    return this.value$;
  }

  add(membership: Membership): void {
    this.afs.collection(`memberships`).add(membership);
  }

  update(membershipKey: string, membership: Membership): Promise<void> {
    return this.afs.doc(`memberships/${membershipKey}`).update(membership);
  }

  get(membershipKey: string): Observable<Membership> {
    return this.db.doc$<Membership>(`memberships/${membershipKey}`);
  }

  delete(membershipKey: string): void {
    this.afs.doc(`memberships/${membershipKey}`).update({trash: true});
  }

  getReference(membershipKey: string): DocumentReference {
    return this.db.doc(`memberships/${membershipKey}`).ref;
  }

  createSession(amount: number, description): Promise<any> {
    const url = `http://localhost:4200/user/memberships`;
    return this.http.post(`${environment.apiBaseURL}/createSession`, {
      amount: amount * 100,
      successUrl: url,
      cancelUrl: url,
      description
    }).pipe(take(1))
      .toPromise();
  }

  getStatus(subscription: string): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/getSubscriptionStatus`, {subscription})
      .pipe(take(1))
      .toPromise();
  }


  cancel(subscription, email = null): Promise<any> {
    return this.http.post(`${environment.apiBaseURL}/cancelSubscription`, {
      subscription,
      from: 'App',
      email
    }).pipe(take(1))
      .toPromise();
  }

  addCancellationOpinion(optionSelected: any, customOption: any, userKey: string) {
    this.db.col(`cancellationOpinions`).add({
      optionSelected,
      customOption,
      user: this.db.getReference(`users/${userKey}`),
      createdAt: new Date().getTime()
    });
  }
}
