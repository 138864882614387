import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {User} from '../../../shared/interfaces/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../shared/services/user.service';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {AlertService} from '../../../shared/template-services/alert.service';
import {AuthService} from '../../../shared/template-services/auth.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
})
export class UpdateProfileComponent implements OnInit {
  user: User = {key: ''} as User;
  userKey: string;
  userForm: FormGroup;
  formSubmitted: boolean = false;
  update: boolean = false;
  birthDateDate: any;
  birthDateNumber: number;
  path;

  constructor(public modalController: ModalController,
              private formBuilder: FormBuilder,
              private _user: UserService,
              private camera: Camera,
              private _alert: AlertService) {
    this.createForm();
  }

  ngOnInit() {
    this.loadUserInformation();
    this.userForm.patchValue(this.user);
    this.birthDateNumber = this.birthDateDate.getTime();
  }

  loadUserInformation() {
    this._user.getCurrentUser().subscribe(user => {
      this.user = user;
    });

  }

  get formControls() {
    return this.userForm.controls;
  }

  createForm(): void {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      birthDate: [''],
      trash: [false],
    });
  }

  async submit() {
    this.formSubmitted = true;
    if (this.userForm.valid) {

      await this.userForm.patchValue({birthdate: new Date(this.userForm.value.birthdate).getTime()});

      await this._user.update(this.user.key, this.userForm.value);
      this.update = true;

      this.dismissModal();
    }
  }

  dismissModal() {
    this.modalController.dismiss().then(r => r = null);
  }
}
