import {Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy, AfterViewInit} from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {PaymentService} from '../../services/payment.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from '../../../shared/services/user.service';
import {Subscription} from 'rxjs';
import {Stripe} from '@ionic-native/stripe/ngx';
import {FormBuilder, Validators} from '@angular/forms';

declare const stripe;
declare const elements;

@Component({
  selector: 'app-add-card-payment',
  templateUrl: './add-card-payment.component.html',
  styleUrls: ['./add-card-payment.component.scss'],
})
export class AddCardPaymentComponent implements OnDestroy {
  private createCardSubscription: Subscription = new Subscription;
  cardFormGroup = this.formBuilder.group({
    number: ['', Validators.required],
    expMonth: ['', Validators.required],
    expYear: ['', Validators.required],
    cvc: ['', Validators.required],
  });

  constructor(public modalCtrl: ModalController,
              private cd: ChangeDetectorRef,
              private _payment: PaymentService,
              private loadingCtrl: LoadingController,
              private afs: AngularFirestore,
              private stripe: Stripe,
              private formBuilder: FormBuilder,
              private alertController: AlertController) {
    // this.stripe.setPublishableKey('pk_test_51GvXsvDH8xyZEFC5sCmnsHtIaiqnIIvwFiHHZKxLTqe8nUJPHEfhJjE55aWzNjNeVtVyA60BFmCQOrr0oJEvwLyN00VibijpKm');
    this.stripe.setPublishableKey('pk_live_51GvXsvDH8xyZEFC5jdFnD8xiwcWuQnc5LNt40FMP1dcH6p9NZZFcfp0hL49aa4apUe3OMiA0mezIRG3O4DMCXZJw00070ySkwW');
  }

  ngOnDestroy() {
    this.createCardSubscription.unsubscribe();
  }

  async onSubmit() {
    await this.presentLoading('Registrando tarjeta, podría demorar unos minutos');

    this.stripe.createCardToken(this.cardFormGroup.value)
      .then(async token => {
        console.log(token);
        const createCardDoc = await this._payment.createCard(token.id);
        this.createCardSubscription = this.afs.doc(`users/${UserService.uid}/createCard/${createCardDoc.id}`)
          .valueChanges()
          .subscribe((doc: any) => {
            if (!!doc.ok) {
              this.loadingCtrl.dismiss();
              this.modalCtrl.dismiss(true);
            } else if (!!doc.err) {
              this.loadingCtrl.dismiss();
              this.presentAlert('Error al registrar la tarjeta');
            }
          });
      })
      .catch(async error => {
        await this.loadingCtrl.dismiss();
        console.error(error);
        this.presentAlert(PaymentService.getMessageError(error));
      });
  }

  async presentLoading(message: string): Promise<void> {
    const loading = await this.loadingCtrl.create({
      message,
      spinner: 'circular'
    });
    return await loading.present();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Alerta',
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }
}
