import {Component, Input, OnInit} from '@angular/core';
import {Membership} from '../../../shared/interfaces/membership';
import {MembershipService} from '../../../shared/services/membership.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-membership-profile',
  templateUrl: './membership-profile.component.html',
  styleUrls: ['./membership-profile.component.scss'],
})
export class MembershipProfileComponent implements OnInit {
  @Input() user: any;
  membership: Membership;
  currentDate: number = (new Date()).getTime();

  constructor(private _membership: MembershipService) {
  }

  async ngOnInit() {
    this.membership = await this._membership.get(this.user.membership.reference.id).pipe(take(1)).toPromise();
  }

}
